import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`:02 Pause Bench Press 5×2\\@80%1rm`}</p>
    <p>{`Strict CTB Pullups 5×2`}</p>
    <p>{`then,`}</p>
    <p>{`15:00 amrap:`}</p>
    <p>{`8-Pullups (Rx+ CTB Pullups)`}</p>
    <p>{`12-Ring Pushups`}</p>
    <p>{`16/12-Calorie Assault Bike`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      